import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    accessToken: "",
  });
  const [loading, setLoading] = useState(true);

  axios.defaults.headers.common["Authorization"] = auth?.accessToken;

  useEffect(() => {
    const loadAuthData = () => {
      const data = localStorage.getItem("auth");
      if (data) {
        try {
          const parsedData = JSON.parse(data);
          setAuth({
            user: parsedData.user,
            accessToken: parsedData.accessToken,
          });
        } catch (error) {
          console.error(
            "Failed to parse authentication data from local storage:",
            error
          );
        }
      }
      setLoading(false);
    };

    loadAuthData();
  }, []);

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify(auth));
  }, [auth]);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {!loading ? children : null}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
