import React, { useEffect } from 'react';

const Modal = ({ isOpen, onClose, imageSrc }) => {
    useEffect(() => {
        const body = document.body;
        const modalBackdrop = document.getElementById('modal-backdrop');

        if (isOpen) {
            body.style.overflow = 'hidden';
            modalBackdrop.style.display = 'block';
        } else {
            body.style.overflow = 'auto';
            modalBackdrop.style.display = 'none';
        }

        return () => {
            body.style.overflow = 'auto';
            modalBackdrop.style.display = 'none';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div id="modal-backdrop" className="fixed z-[5000] top-0 left-0 w-full h-full bg-[#0000003a] backdrop-filter backdrop-blur-[2px] backdrop-grayscale-[0.2] backdrop-brightness-50"></div>
            <div className="fixed z-[5001] top-0 left-0 w-full h-full overflow-hidden flex justify-center items-center">
                <div className="modal-content relative">
                    <span className="close cursor-pointer" onClick={onClose}>
                        <img src={require("../../assets/images/cross.png")} className="w-[30px] -right-3 -top-2 h-[30px] absolute" alt="" />
                    </span>
                    <img className="sm:max-w-[70vw] max-w-[80vw] h-[40vh] sm:h-[500px]" src={imageSrc} alt="" />
                </div>
            </div>
        </>
    );
};

export default Modal;
