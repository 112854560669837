import axios from "axios";
import React, { useState } from "react";
import { IoMdCall } from "react-icons/io";
import { RxCross1 } from "react-icons/rx";
import Lottie from "react-lottie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SupportAnimationData from "../../assets/images/contact_card.json";
import { API } from "../../utils/constants";

const ContactForm = () => {
  const [isPopupOpen, setisPopupOpen] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    reason: "",
  });
  const [validationErrors, setValidationErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setValidationErrors({
      ...validationErrors,
      [name]: null,
    });
  };

  const handleNeedHelpClick = () => {
    setValidationErrors("");
    setisPopupOpen(!isPopupOpen);

  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    try {
      const errors = {};
      if (!formData.name.trim()) {
        errors.name = "Please enter your name";
      }
      if (!formData.email.trim()) {
        errors.email = "Please enter your email";
      }
      if (!formData.phoneNumber.trim()) {
        errors.phoneNumber = "Please enter your phone number";
      }
      if (!formData.reason.trim()) {
        errors.reason = "Please enter a reason";
      }
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);

        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setValidationErrors({
          ...validationErrors,
          email: "Please enter a valid email address",
        });
        return;
      }

      const phoneRegex = /^\d{10}$/;
      if (!phoneRegex.test(formData.phoneNumber)) {
        setValidationErrors({
          ...validationErrors,
          phoneNumber: "Please enter a valid 10-digit phone number",
        });
        return;
      }

      const response = await axios.post(`${API}/contact/submit`, formData);
      console.log(response.data);

      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        reason: "",
      });

      // Show toast notification for successful submission
      toast.success("Email sent successfully");
    } catch (error) {
      toast.error("Error submitting contact details:", error);
    }
  };

  const ContactInputStyles =
    "my-1 lg:min-w-[400px] w-[280px] max-md:w-[80vw]  min-h-5 border-2 border-solid border-white rounded-full bg-transparent placeholder:text-white placeholder:opacity-100 placeholder:text-md  p-3  text-white text-md focus:ring-0 focus:outline-none";

  return (
    <div className=" md:py-10 max-md:pb-4 w-full md:w-full h-full flex flex-col items-center max-md:justify-evenly">
      <div className="flex flex-col md:items-start items-center justify-evenly  w-[80%] md:w-[80%] ">
        <h1 className="font-bold gradient-text2 max-md:hidden text-13xl text-left my-5 md:ml-3">Request a Call back</h1>
        <form className="relative" onSubmit={handleSubmit}>
          <input
            className={ContactInputStyles}
            placeholder="Your Name"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          /><br />
          {validationErrors.name && <small className="text-red-500 ml-4">{validationErrors.name}</small>}
          <input
            className={ContactInputStyles}
            placeholder="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          /><br />
          {validationErrors.email && <small className="text-red-500 ml-4">{validationErrors.email}</small>}
          <input
            className={ContactInputStyles}
            placeholder="Phone Number"
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          /><br />
          {validationErrors.phoneNumber && <small className="text-red-500 ml-4">{validationErrors.phoneNumber}</small>}
          <textarea
            className={`my-1 lg:min-w-[400px] w-[280px] max-md:w-[80vw] mx-auto border-2 border-solid border-white rounded-2xl bg-transparent placeholder:text-white placeholder:opacity-100 placeholder:text-md p-3 text-white text-lg focus:ring-0 focus:outline-none h-40 resize-none`}
            placeholder="Reason"
            type="text"
            name="reason"
            value={formData.reason}
            onChange={handleInputChange}
          /> <br />
          {validationErrors.reason && <small className="text-red-500 ml-4">{validationErrors.reason}</small>}
          <div className="my-3 mx-3 lg:min-w-[400px] w-[280px] max-md:w-[80vw] max-md:gap-1  flex justify-between relative">
            <div
              onClick={handleNeedHelpClick}
              className="btn  bg-[#EA4335] max-md:text-[13px]  hover:bg-[#EA4335] text-white text-lg  rounded-full border-none  "
            >
              Need Help
            </div>
            <button
              type="submit"
              className="btn  max-md:text-[13px]   bg-[#EA4335] hover:bg-[#EA4335] text-white text-lg  rounded-full border-none "
            >
              Submit
            </button>

            {isPopupOpen && (
              <div className="absolute  md:right-[-50px] md:top-[-100px] top-[-120px] flex bg-opacity-50 z-50">
                <div className="bg-[#FFFBEE]  rounded-xl">
                  <div onClick={handleNeedHelpClick} className="absolute m-2 cursor-pointer  text-[18px] right-0">
                    <RxCross1 />
                  </div>
                  <Needhelppopup />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

const Needhelppopup = () => {
  return (
    <div className=" ">
      <div className="mt-8">
        <h1 className="py-1 px-4 text-xs  md:text-[15px] ">Need Guidance or Help in Filling Form?</h1>
        <h1 className="md:mx-8 mx-3 pb-2">
          <p className="md:text-sm text-xs flex justify-between ">
            <span>Sachin Rana</span>{" "}
            <a className="no-underline text-black" href="tel:+919560443520">
              <IoMdCall /> +91 9560443520
            </a>{" "}
          </p>
          <p className=" flex md:text-sm text-xs justify-between   ">
            <span>Virendra Pratap</span>{" "}
            <a className="no-underline text-black" href="tel:+918279662680">
              <IoMdCall /> +91 8279662680
            </a>{" "}
          </p>
        </h1>
      </div>
    </div>
  );
};

const Contact = () => {
  return (
    <div className="pt-10  md:mx-20 md:pt-15 bg-white pb-32">
      <div
        className="bg-gradient-to-br overflow-hidden from-[#e78f8fd7] to-[#a691f5de]  rounded-2xl flex flex-col-reverse items-center justify-evenly md:flex-row md:justify-evenly max-w-[1000px] mx-auto max-sm:mx-2 max-md:mx-8  "
      >
        <div className="h-full max-sm:mx-auto   sm:w-[500px] flex justify-center">
          <ContactForm />
        </div>
        <div className="flex justify-center w-[80%] sm:w-[400px] items-center ">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: SupportAnimationData,
            }}
          />

        </div>
        <h1 className="font-bold md:hidden gradient-text2 text-[25px] text-left my-5 ">Request a Call back</h1>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Contact;